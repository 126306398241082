<template>
	<v-card flat>
		<v-toolbar dense :extended="showFilters" flat wrap>
			<v-layout row align-center justify-center>
				<TasksManagerToolbarCounters class="shrink mx-2" />
				<TasksManagerToolbarSearch v-if="$vuetify.breakpoint.mdAndUp" class="mx-2" />
				<v-flex shrink>
					<v-switch v-model="showFilters" color="primary" hide-details small :label="!minimized ? $t('client_workspace.tasks.filtering.title') : null" />
				</v-flex>
				<TasksManagerToolbarActions :minimized="isMinimized" class="shrink" />
			</v-layout>
			<template v-if="showFilters" v-slot:extension>
				<TasksManagerToolbarFilters :minimized="isMinimized" />
			</template>
		</v-toolbar>
	</v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerToolbar',
	components: {
		TasksManagerToolbarCounters: () => ({
			component: import('@/components/TasksManager/Toolbar/TasksManagerToolbarCounters')
		}),
		TasksManagerToolbarSearch: () => ({
			component: import('@/components/TasksManager/Toolbar/TasksManagerToolbarSearch')
		}),
		TasksManagerToolbarActions: () => ({
			component: import('@/components/TasksManager/Toolbar/TasksManagerToolbarActions')
		}),
		TasksManagerToolbarFilters: () => ({
			component: import('@/components/TasksManager/Toolbar/TasksManagerToolbarFilters')
		})
	},
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			showFilters: !this.minimized
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant
		}),
		isMinimized: function () {
			return this.$vuetify.breakpoint.mdAndDown || this.minimized
		}
	},
	methods: {
		toggleFilters: function () {
			this.showFilters = !this.showFilters
		}
	}
}
</script>
